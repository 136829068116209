import React from 'react'
import Footer from '../components/layouts/Footer'
import Header from '../components/layouts/Header'
import aboutText from '../assets/img/about-text.svg'

const About = () => {
    return (
        <>
            <Header />
            <section>
                <div className="masthead container">
                    <img src={aboutText} className="hero-text" />
                    <p className="p-lg text-black">
                        El Mountain is a design, website and app development studio that helps entrepreneurs start, grow and scale their dreams through beautifully designed powerful mobile responsive websites and applications.
                    </p>
                </div>
            </section>
            <img src={require('../assets/img/about-climb.jpg')} className="hero-text" />
            <section>
                <div className="container">
                    <p className="p-lg">
                        We are experts in identifying business needs, creating strategy and then delivering the best solution to meet those needs. This can be a website, an app, or both. We create all our projects with a full understanding of target audience, features and the end results.
                    </p>
                    <div className="features">
                        <div className="feature">
                            <div className="feature-col">
                                <img src={require('../assets/img/about-work.jpg')} />
                            </div>
                            <div className="feature-col text-justify">
                                <p className="p-md">
                                    We believe that creativity is all about building relationships with our clients and understanding their needs. Through collaboration we're able to create something really special for our clients and partners.
                                </p>
                                <br />
                                <p className="p-md">
                                    We pride ourselves lovers of design and problem solving and have built a solid reputation and an ever-lasting relationships with all our clients and partners. Each individual in our team personally enjoys meeting new people, helping them with their project(s) at hand, seeing it through to completion until they are completely satisfied with the results they achieved.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-dark section-stats section-lg">
                <div className="container">
                    <div className="section-header">
                        <h4 className="support light">Some Facts</h4>
                        <h3 className="heading">Fueled by Adrenaline Rush, always!</h3>
                    </div>
                    <div className="stats">
                        <div className="stat">
                            <div className="numbers">
                                <strong className="number">10</strong>
                                <span className="desc">years of experience</span>
                            </div>
                        </div>
                        <div className="stat">
                            <div className="numbers">
                                <strong className="number">170+</strong>
                                <span className="desc">completed projects</span>
                            </div>
                        </div>
                        <div className="stat">
                            <div className="numbers">
                                <strong className="number">55+</strong>
                                <span className="desc">eCommerce Websites</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <p className="p-lg">
                        We're 100% committed to helping you build your business and create an all-around better digital experience. We are experts in branding, design, development and digital marketing, so no matter how big or small your project is, we'll make you want to go digital!
                    </p>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default About