import React from 'react'
import Footer from '../components/layouts/Footer'
import Header from '../components/layouts/Header'
import contactText from '../assets/img/contact-text.svg'
import { useForm, ValidationError } from '@formspree/react';


const Contact = () => {
    const [state, handleSubmit] = useForm("mqkjpyzg");
    return (
        <>
            <Header />
            <section className="section-sm">
                <div className="masthead container">
                    <img src={contactText} className="hero-text" />
                    <p className="p-lg text-black">
                        Fill in the form below and tell us about your project in detail to get an early call.
                    </p>
                </div>
            </section>
            <section>
                <div className="container contact">
                    <form className="contact-form" onSubmit={handleSubmit}>
                        {
                            state.succeeded ? <>
                            <h1 className="alert-success">Thank You, We have received your request, we'll contact you as soon as possible. We usually contact in 4-8 hours, if your application qualifies.</h1>
                            </> :
                                <>
                                    <input type="text" placeholder="Your Name" name="name" required />
                                    <input type="email" placeholder="Your E-Mail" name="email" required />
                                    <input type="text" placeholder="Your Mobile Number" name="mobile" required />
                                    <textarea name="details" placeholder="Tell us about your project in detail" required></textarea>
                                    <input type="text" placeholder="Budget" name="budget" required />
                                    <button className="btn" disabled={state.submitting}>Send Request</button>
                                </>
                        }
                    </form>
                    <div className="contact-img">
                        <img src={require("../assets/img/contact-us.jpg")} alt="" />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Contact