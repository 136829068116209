import React from 'react'
import Footer from '../components/layouts/Footer'
import Header from '../components/layouts/Header'
import 'remixicon/fonts/remixicon.css';
import '../assets/svgs/constellation.css'
import '../assets/svgs/constellation-light.css'
import introText from '../assets/img/intro-text.svg'
import vAvatar from '../assets/img/avatars/male/v.svg'
import sAvatar from '../assets/img/avatars/male/s.svg'
import pAvatar from '../assets/img/avatars/female/p.svg'
import { Link } from 'react-router-dom';


const Home = () => {
    return (
        <>
            <Header />
            <section>
                <div className="masthead container">

                    <div className="pill-group">
                        <span className="pill">
                            <i className="ri ri-shopping-cart-fill"></i>
                            eCommerce
                        </span>
                        <span className="pill">
                            <i className="ri ri-smartphone-fill"></i>
                            Apps
                        </span>
                        <span className="pill">
                            <i className="ri ri-window-2-fill"></i>
                            Websites
                        </span>
                        <span className="pill">
                            <i className="ri ri-heart-fill"></i>
                            Branding
                        </span>
                    </div>

                    <img src={introText} className="hero-text" />

                    <div className="testimonial">
                        <img src={pAvatar} alt="" className="avatar" />
                        <div className="testimonial-text">
                            <p>
                                My dance insititute has seen a rapid growth in number of students & profits, all thanks to El Mountain's eCommerce solutions & marketing campaigns.
                            </p>
                            <div className="testimonial-author">
                                Priya Das
                                <small>Founder, <a target="_blank" href="https://www.prayatna.co">Prayatnaप्रयत्न</a></small>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container cta">
                <Link to="/contact" className="btn">Let's Talk</Link>
            </div>
            <section className="section-services">
                <div className="services container">
                    <div className="section-header">
                        <h3 className="heading">Our Creative Capabilities</h3>
                        <p className="p-md">
                            Our design and engineering team integrates great design with intelligent functionality & technology to deliver world class products.
                        </p>
                    </div>
                    <div className="services-list">
                        <ul>
                            <li style={{ backgroundColor: '#ebf2f8' }}>
                                <h4 className="heading">User Experience</h4>
                                <p className="text">
                                    We create outstanding user experiences while embracing your brand's personality and your traget audience
                                </p>
                            </li>
                            <li style={{ backgroundColor: '#efe6f1' }}>
                                <h4 className="heading">Ecommerce</h4>
                                <p className="text">
                                    We are the experts when it comes to eCommerce, starting a new business? We can help you start selling in less than 2 hours.
                                </p>
                            </li>
                            <li style={{ backgroundColor: '#daf1f1' }}>
                                <h4 className="heading">Web Design</h4>
                                <p className="text">
                                    We're a design studio with an experience of creating world class storytelling websites that are responsive & SEO ready.
                                </p>
                            </li>
                            <li style={{ backgroundColor: '#ebf2f8' }}>
                                <h4 className="heading">Development - Mobile & Internet Apps</h4>
                                <p className="text">
                                    Our full-stack team has helped businesses create enterprise level web & mobile apps to help their businesses grow.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="section-testimonials section-dark" id="testimonials">
                <div className="container">
                    <div className="section-header">
                        <h4 className="support light">Testimonials</h4>
                        <h3 className="heading">Words from our Clients</h3>
                    </div>
                    <div className="testimonials">
                        <div className="testimonial">
                            <img src={pAvatar} alt="" className="avatar avatar-small" />
                            <div className="testimonial-text">
                                <p>
                                    My dance insititute has seen a rapid growth in number of students & profits, all thanks to El Mountain's eCommerce solutions & marketing campaigns. There super fast delivery and dedication makes them my go to technology partner.
                                </p>
                                <div className="testimonial-author">
                                    Priya Das
                                    <small>Founder, Prayatnaप्रयत्न</small>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial">
                            <img src={vAvatar} alt="" className="avatar avatar-small" />
                            <div className="testimonial-text">
                                <p>
                                    El Mountain is a fantastic company to work with. They have the amazing ability to create beautiful, functional and easy to use designs that are a joy to work with. I am always happy with their customer service and they make it easy for me to get in touch with them.
                                </p>
                                <div className="testimonial-author">
                                    Vinay Kumar
                                    <small>Founder, MindSquare</small>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial">
                            <img src={sAvatar} alt="" className="avatar avatar-small" />
                            <div className="testimonial-text">
                                <p>
                                    I have been working with El Mountain for a few months now, and they have created some amazing work. Not only are they great at what they do, but they are really easy to work with and always meet deadlines.
                                </p>
                                <div className="testimonial-author">
                                    Shubham Kumar
                                    <small>Founder, FortunateOnes</small>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial">
                            <img src={sAvatar} alt="" className="avatar avatar-small" />
                            <div className="testimonial-text">
                                <p>
                                    El Mountain has been such a great find. The pricing is so affordable and they've helped me create amazing products that are selling extremely well. I am really grateful that I found them.
                                </p>
                                <div className="testimonial-author">
                                    Shruti Kushwaha
                                    <small>Co-Founder, Tradography</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-why">
                <div className="container">
                    <div className="section-header">
                        <h4 className="support">What makes us different?</h4>
                        <h3 className="heading">Working with us is fun</h3>
                    </div>
                    <div className="why">
                        <p className="why-row p-md">
                            Magicians at work, we create world class UX, UI and Designs
                        </p>
                        <p className="why-row p-md">
                            We build powerful enterprise grade softwares and mobile apps for businesses
                        </p>
                        <p className="why-row p-md">
                            We help startups & other businesses unleash their potential with our full-stack design & engineering
                        </p>
                        <p className="why-row p-md">
                            Never afraid of the challenges, we push ourselves to go beyond what's possible. Always!
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Home