import React from 'react'
import { Link, NavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <footer>
            <div className="container footer">
                <div className="footer-column">
                    <div className="section-header">
                        <h4 className="support">Want us to work on your project?</h4>
                        <h3 className="heading">
                            <Link to="/contact">Let's Talk</Link>
                        </h3>
                    </div>
                </div>
                <div className="footer-column text-right">
                    <ul className="nav">
                        <li>
                            <NavLink to="/">Home</NavLink>
                        </li>
                        <li>
                            <NavLink to="/about">About</NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact">Contact</NavLink>
                        </li>
                        <li>
                            <NavLink to="/pricing">Pricing</NavLink>
                        </li>
                        <li>
                            <NavLink to="/terms-and-conditions">Terms</NavLink>
                        </li>
                        <li>
                            <NavLink to="/privacy-policy">Privacy</NavLink>
                        </li>
                        <li>
                            <NavLink to="/cancellation-and-refunds">Refunds</NavLink>
                        </li>
                    </ul>
                    <div className="section-header no-margin">
                        <h4 className="support">
                            <a href="mailto:hello@elmountain.com">hello@elmountain.com</a>
                        </h4>
                        <p className="location">Made in Love for Design & Technology in New Delhi</p>
                    </div>
                    <ul className="social-media">
                        <li>
                            <a href="https://www.instagram.com/elmountain.studio/" target="_blank" rel="noreferrer">
                                <i className="ri ri-instagram-fill"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/elmountain.studio/" target="_blank" rel="noreferrer">
                                <i className="ri ri-facebook-fill"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.twitter.com/lmountainstudio/" target="_blank" rel="noreferrer">
                                <i className="ri ri-twitter-fill"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default Footer