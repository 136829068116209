import React from 'react'
import { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link';
import { CSSTransitionGroup } from 'react-transition-group'

const Header = () => {
    const [menu, setMenu] = useState(false)

    function showMenu() {
        setMenu(true);
    }

    function closeMenu() {
        setMenu(false);
    }

    return (
        <>
            <header>
                <Link to="/contact" target="_blank" rel="noreferrer" className="icon">
                    <i className="ri ri-chat-3-fill"></i>
                </Link>
                <Link to="/" className="logo"></Link>
                <button onClick={showMenu} className="icon">
                    <i className="ri ri-menu-4-fill"></i>
                </button>
            </header>
            <CSSTransitionGroup transitionName="fade" 
                transitionEnterTimeout={1000} 
                transitionLeaveTimeout={1000}>
                {
                    menu &&
                    <nav key="nav">
                        <div>
                            <Link to="/" className="logo logo-light"></Link>
                            <button onClick={closeMenu} className="close">
                                <i className="ri ri-close-circle-line"></i>
                            </button>
                        </div>
                        <ul>
                            <li><NavLink to="/">Home</NavLink></li>
                            <li><NavLink to="/about">About</NavLink></li>
                            <li><NavHashLink to="/#testimonials">Testimonials</NavHashLink></li>
                            <li><NavLink to="/contact">Contact</NavLink></li>
                            <li><a href="mailto:hello@elmountain.com">E-Mail Us</a></li>
                            <li><a target="_blank" href="https://wa.me/+919667115266">WhatsApp Us</a></li>
                        </ul>
                    </nav>
                }
            </CSSTransitionGroup>
        </>
    )
}

export default Header