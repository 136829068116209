import React from 'react'
import Footer from '../components/layouts/Footer'
import Header from '../components/layouts/Header'

const Terms = () => {
    return (
        <>
            <Header />
            <div className="page page-md">
                <section>
                    <div className="masthead container">
                        <h1 className="hero-heading">
                            Terms & Conditions
                        </h1>
                        <p className="p-md">
                            The website www.elmountain.com is owned by El Mountain, all the other websites listed on our website for showcase purposes are solely owned by the business owners of the respective websites. By using our website and working with us you agree to the following set of terms important to both you and us.
                        </p>
                    </div>
                </section>
                <section>
                    <h2>General</h2>
                    <ul>
                        <li>
                            Upon quote acceptance, we begin the project after the initial/advance payment is processed. Before starting the project, we may hold a scoping discussion for the details requirements.
                        </li>
                        <li>
                            After identifying style preferences and the company's branding, we send a mock-up that needs to be reviewed by you. We generally make up to 3-5 alterations or based on your budget.
                        </li>
                        <li>
                            When the project is completed, we will provide it to you for review and modification. You need to choose when you want your project to go live, so we can prepare for the release.
                        </li>
                        <li>
                            The project is made live in 1-2 working days once your account is cleared and full payment is made.
                        </li>
                        <li>
                            We are flexible and willing to accommodate any changes you request based on hourly basis after the project is delivered.
                        </li>
                    </ul>
                    <h2>Content Usage</h2>
                    <ul>
                        <li>
                            All the logos, brands, labels, names or any combination thereof appearing in this site are properties either owned or used under license by El Mountain. The use of these properties is strictly prohibited.
                        </li>
                        <li>
                            All the logos, graphics, videos of our customers uploaded for showcase on our website remains their exclusive property. El Mountain does not claim any ownership of that content.
                        </li>
                    </ul>
                    <h2>Our Rights</h2>
                    <ul>
                        <li>
                            We reserve the right to modify, terminate or cancel the services for any reason without notice at any time in case of violation of our terms.
                        </li>
                        <li>
                            Written or verbal threats, abuse of any El Mountain employee or representative can result in instant termination of the contract or any service agreement.
                        </li>
                    </ul>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default Terms