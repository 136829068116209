import React from 'react'
import Footer from '../components/layouts/Footer'
import Header from '../components/layouts/Header'

const Privacy = () => {
    return (
        <>
            <Header />
            <div className="page page-md">
                <section>
                    <div className="masthead container">
                        <h1 className="hero-heading">
                            Privacy Policy
                        </h1>
                        <p className="p-md">
                            This page informs you about our policies regarding collection and use of your and your customer's personal data. We respect privacy of our users and use all their personal information wisely. We do not collect information that is not relevant to the use of our services.
                        </p>
                    </div>
                </section>
                <section>
                    <h2>General</h2>
                    <ul>
                        <li>
                            We only take your personal information such as name, contact information and address from you. We use this information for communication, billing and fulfillment of our services.

                        </li>
                        <li>
                            We might share customer's information such as email, phone number, address with the payment partner for payments.
                        </li>
                        <li>
                            We do not share your or your customer's personal information with any other organization or governing body, we might share the information with law governing bodies in case of any legal violations of our policies.
                        </li>
                        <li>
                            We do not take or store your payment information on our servers.
                        </li>
                    </ul>
                    <h2>Payment</h2>
                    <ul>
                        <li>
                            We use Razorpay for processing payments. Razorpay do not store your card data on their servers. The data is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) when processing payment. Your purchase transaction data is only used as long as is necessary to complete your purchase transaction. After that is complete, your purchase transaction information is not saved.
                        </li>
                        <li>
                            Our payment gateway adheres to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover.
                        </li>
                        <li>
                            PCI-DSS requirements help ensure the secure handling of credit card information by our store and its service providers.
                        </li>
                        <li>
                            For more insight, you may also want to read terms and conditions of razorpay on https://razorpay.com
                        </li>
                    </ul>
                    <h2>Third Party Services</h2>
                    <ul>
                        <li>
                            We may use third party services for payments, analytics and user access. Such websites have their own privacy policy and doesn't have any relation to us.
                        </li>
                        <li>
                            Once you redirected to a third-party website, you are no longer governed by this Privacy Policy or our website's Terms of Service.
                        </li>
                    </ul>
                    <h2>Security</h2>
                    <ul>
                        <li>
                            Our website protects your personal information, we have implemented best practices to make sure it is not misused, accessed, disclosed or altered to/by anyone.
                        </li>
                    </ul>
                    <h2>Cookies</h2>
                    <ul>
                        <li>
                            We use cookies to maintain session and personlization data of our users.
                        </li>
                        <li>
                            If you'd like to access, modify or delete your information from our website, you can write an email at hello@elmountain.com
                        </li>
                    </ul>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default Privacy