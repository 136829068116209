import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './styles/App.scss';
import 'remixicon/fonts/remixicon.css'
import Footer from './components/layouts/Footer';
import Header from './components/layouts/Header';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import ScrollToTop from './actions/scrollToTop';
import Pricing from './pages/Pricing';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Refunds from './pages/Refunds';

function App() {
    return (
        <div>
            <BrowserRouter>
                <ScrollToTop />
                <Routes>
                    <Route exact index element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/privacy-policy" element={<Privacy />} />
                    <Route path="/terms-and-conditions" element={<Terms />} />
                    <Route path="/cancellation-and-refunds" element={<Refunds />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
