import React from 'react'
import Footer from '../components/layouts/Footer'
import Header from '../components/layouts/Header'

const Refunds = () => {
    return (
        <>
            <Header />
            <div className="page page-md">
                <section>
                    <div className="masthead container">
                        <h1 className="hero-heading">
                            Cancellation & Refunds
                        </h1>
                        <p className="p-md">
                            This page informs you about our policies regarding cancellation of the services and refunds.
                        </p>
                    </div>
                </section>
                <section>
                    <h2>Eligibility for Refund</h2>
                    <ul>
                        <li>
                            At El Mountain, we consider each and every project of utmost importance with final delivery in sight. However in case of you are not satisfied and we are unable to reach a common ground with you after communication, you can file a refund claim.
                        </li>
                        <li>
                            If you are not satifsied and the project has not been started, we can initiate full refund after the case goes through a dispute resolution process.
                        </li>
                        <li>
                            If you are not satifsied and the project work has started, only a partial refund can be considers based on the dispute resolution process as per the proportion of work done.
                        </li>
                        <li>
                            The above policies doesn't gaurantee refund and is subject to the outcomes of dispute resoltion process at El Mountain
                        </li>
                    </ul>
                    <h2>Refund of 3rd Party Subscriptions</h2>
                    <ul>
                        <li>
                            This policy does not covers protection for any third party services or products subscribed or purchased for the completion of project. The team at El Mountain can try for the refund process but are not responsible and liable for any losses.
                        </li>
                    </ul>
                    <p>
                        If you have queries or suggestions regarding our refund policies kindly e-mail us at hello@elmountain.com
                    </p>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default Refunds